import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '@utilitywarehouse/partner-ui-mui-theme/lib/main.css';
import Immutable from 'immutable';
import { APP_VERSION, ENVIRONMENT, CONFIG_SENTRY_DSN } from 'config';
import { initAnalytics } from './lib/analytics';
import Sentry from './lib/analytics/sentry';
import configureStore from './redux/store/configureStore';
import './lib/polyfills/url';

const analytics = initAnalytics();

const initialState = Immutable.Map();
export const reduxStore = configureStore(
  initialState,
  analytics.reduxMiddlewares
);

Sentry.init(CONFIG_SENTRY_DSN, ENVIRONMENT, APP_VERSION);
