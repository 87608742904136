// FIXME: to be removed, we now use breakpoints from the mui theme
// Media Queries For Radium

export const breakpoints = {
  MOBILE_S: 320,
  MOBILE_M_PLUS: 321,
  MOBILE_M: 375,
  MOBILE_L_PLUS: 378,
  MOBILE_L: 425,

  TABLET_PLUS: 426,
  TABLET: 768,

  LAPTOP_PLUS: 769,
  LAPTOP: 1024,

  LAPTOP_L_PLUS: 1025,
  LAPTOP_L: 1140,
  FOUR_K: 2560,
};

// note: please do not update the max-width to maxWidth since it'll break
// the old pages which still use Radium (like CBC)
export default {
  MOBILE_IPHONE_5S:
    '@media only screen  and (min-device-width : 320px) and (max-device-width : 568px)',
  MOBILE_M: `@media only screen and (max-width: ${breakpoints.MOBILE_M}px)`,
  MOBILE_L: `@media only screen and (max-width: ${breakpoints.MOBILE_L}px)`,

  TABLET_PLUS: `@media only screen and (min-width: ${breakpoints.TABLET_PLUS}px)`,
  TABLET: `@media only screen and (max-width: ${breakpoints.TABLET}px)`,

  LAPTOP_PLUS: `@media only screen and (min-width: ${breakpoints.LAPTOP_PLUS}px)`,
  LAPTOP: `@media only screen and (max-width: ${breakpoints.LAPTOP_PLUS}px)`,

  LAPTOP_L_PLUS: `@media only screen and (min-width: ${breakpoints.LAPTOP_L_PLUS}px)`,
  LAPTOP_L_MAX: `@media only screen and (max-width: ${breakpoints.LAPTOP_L}px)`,
  LAPTOP_L: `@media only screen and (max-width: ${breakpoints.LAPTOP_L_PLUS}px)`,
};
