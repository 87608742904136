import { useRef } from 'react';

const useScrollTo = () => {
  const elementRef = useRef(null);

  const scrollElementIntoView = () => {
    if (!elementRef.current) {
      return;
    }

    const { y } = elementRef.current.getBoundingClientRect();

    window.scrollTo({
      top: y + window.scrollY - window.innerHeight / 2,
      behavior: 'smooth',
    });
  };

  return { scrollElementIntoView, elementRef };
};

export { useScrollTo };
