//FIXME: to be removed, we have everything in the mui theme
export default {
  brand: {
    purple: '#440459',
    yellow: '#FED100',
  },
  primary: {
    DarkPurple: '#440459',
    yellow: '#FED100',
    magenta: '#821D7F',
  },
  secondary: {
    lavender: '#dfbadb',
    orange: '#f9a700',
    blue: '#26a7c9',
    green: '#7ac138',
    pink: '#ed62a0',
    lightPink: '#edd9eb',
    darkOrange: '#ee4913',
    red: '#e81d08',
    grey: '#97999b',
    darkGrey: '#6d6e71',
    lightGrey: '#f6f7f7',
    mediumGrey: '#97999b33',
    lighterGrey: '#f6f7f7',
    white: '#ffffff',
    black: '#000',
  },
  pageText: {
    primary: 'rgba(0,0,0,0.77)',
    secondary: 'rgba(0,0,0,0.60)',
  },
};
