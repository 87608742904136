import { useEffect, useRef } from 'react';

const getErrorByName = (name, errors) => {
  const error = errors[name];
  if (error) {
    return error.valid === false ? error : null;
  }

  return null;
};

const getTopErrorFieldName = (fields, errors) =>
  fields.find((name) => Boolean(getErrorByName(name, errors))) ?? null;

const getTopErrorField = (fields, errors) => {
  const fieldName = getTopErrorFieldName(fields, errors);

  if (fieldName) {
    return document.querySelector(`[name="${fieldName}"]`);
  }

  return null;
};

/**
 * @typedef FieldError
 * @type {Object}
 * @property {string} field - field name
 * @property {string} reason - error message
 * @property {boolean} valid - has error
 */

/**
 * Scroll to first input from the top which has an error
 * @param {string[]} fields - ordered array of fields (from the top in the view)
 * @param {Object.<string, FieldError>} errors - form errors
 */
const useScrollInputErrorIntoView = (fields, errors) => {
  const fieldRef = useRef(null);

  useEffect(() => {
    const topErrorField = getTopErrorField(fields, errors);

    if (topErrorField) {
      fieldRef.current = topErrorField;
    }
  }, [errors, fields]);

  const scrollErrorIntoView = () => {
    if (!fieldRef.current) {
      return;
    }

    const { y } = fieldRef.current.getBoundingClientRect();

    window.scrollTo({
      top: y + window.scrollY - window.innerHeight / 2,
      behavior: 'smooth',
    });
  };

  return scrollErrorIntoView;
};

export {
  useScrollInputErrorIntoView,
  getErrorByName,
  getTopErrorFieldName,
  getTopErrorField,
};
