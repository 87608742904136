// FIXME: to be removed, we have everything in the mui theme object
import media from 'constants/media.js';
import colors from 'constants/colors';

export default {
  formWidth: {
    maxWidth: 800,
  },
  device: {
    tabletLimit: {
      display: 'none',
      [media.TABLET]: {
        display: 'inline',
      },
    },
    laptopPlus: {
      display: 'none',
      [media.LAPTOP_PLUS]: {
        display: 'inline',
      },
    },
  },
  fonts: {
    center: {
      textAlign: 'center',
    },
    bold: {
      fontWeight: '700',
    },
    semibold: {
      fontWeight: '600',
    },
    regular: {
      fontWeight: '400',
    },
    light: {
      fontWeight: '300',
    },
    extraLight: {
      fontWeight: '100',
    },
    sebastian: {
      fontFamily: 'Sebastian-informal',
    },
    h1: {
      fontSize: 42,
      lineHeight: '45px',
      fontWeight: '600',
      [media.MOBILE_L]: {
        fontSize: 32,
        lineHeight: '35px',
      },
    },
    h2: {
      fontSize: 36,
      lineHeight: '40px',
      fontWeight: '700',
      [media.TABLET]: {
        fontSize: 28,
        lineHeight: '30px',
      },
      [media.MOBILE_L]: {
        fontSize: 22,
        lineHeight: '24px',
      },
    },
    h3: {
      fontSize: 26,
      fontWeight: '700',
    },
    h4: {
      fontSize: 22,
      fontWeight: '700',
      lineHeight: '24px',
      [media.TABLET]: {
        fontSize: 18,
        lineHeight: '22px',
      },
      [media.MOBILE_L]: {
        fontSize: 16,
        lineHeight: '20px',
      },
    },
    p1: {
      fontSize: 40,
      lineHeight: '42px',
      [media.TABLET]: {
        fontSize: 32,
        lineHeight: '34px',
      },
      [media.MOBILE_L]: {
        fontSize: 28,
        lineHeight: '32px',
      },
    },
    p2: {
      fontSize: 32,
      lineHeight: '34px',
      [media.TABLET]: {
        fontSize: 28,
        lineHeight: '30px',
      },
      [media.MOBILE_L]: {
        fontSize: 22,
        lineHeight: '24px',
      },
    },
    p3: {
      fontSize: 28,
    },
    p4: {
      fontSize: 22,
      lineHeight: '24px',
      [media.TABLET]: {
        fontSize: 18,
        lineHeight: '20px',
      },
      [media.MOBILE_L]: {
        fontSize: 16,
        lineHeight: '18px',
      },
    },
    p5: {
      fontSize: 18,
      lineHeight: '22px',
      [media.TABLET]: {
        fontSize: 16,
        lineHeight: '18px',
      },
      [media.MOBILE_L]: {
        fontSize: 15,
        lineHeight: '17px',
      },
    },
    p6: {
      fontSize: 16,
      lineHeight: '18px',
      [media.TABLET]: {
        fontSize: 15,
        lineHeight: '17px',
      },
      [media.MOBILE_L]: {
        fontSize: 14,
        lineHeight: '16px',
      },
    },
    p7: {
      fontSize: 15,
      lineHeight: '18px',
      [media.TABLET]: {
        fontSize: 14,
        lineHeight: '17px',
      },
      [media.MOBILE_L]: {
        fontSize: 13,
        lineHeight: '15px',
      },
    },
    p8: {
      fontSize: 14,
      lineHeight: '15px',
      [media.TABLET]: {
        fontSize: 12,
        lineHeight: '13px',
      },
      [media.MOBILE_L]: {
        fontSize: 10,
        lineHeight: '11px',
      },
    },
    p9: {
      fontSize: 12,
      lineHeight: '13px',
    },
    link: {
      color: '#9c278f',
      borderBottom: '1px solid #9c278f',
      textDecoration: 'none',
    },
    sectionTitle: {
      fontSize: 36,
      lineHeight: '40px',
      fontWeight: '700',
      [media.TABLET]: {
        fontSize: 28,
        lineHeight: '30px',
      },
      [media.MOBILE_L]: {
        fontSize: 22,
        lineHeight: '24px',
      },
      textAlign: 'center',
      color: colors.primary.DarkPurple,
    },
  },
  heroBackground: {
    background: 'linear-gradient(135deg, #440459 0%, #9c278f 100%)',
  },
  purpleBackground: {
    backgroundColor: colors.brand.purple,
  },
  infoHeader: {
    backgroundColor: colors.secondary.lighterGrey,
    textColor: 'purple',
  },
  RadioContainer: {
    display: 'flex',
    border: '1px solid #cccccc',
    backgroundColor: colors.secondary.lightGrey,
    padding: 10,
    flex: '2 1 auto',
    width: 'auto',
    borderRadius: 3,
    flexGrow: 2,
  },
  FlexLayoutRow: {
    display: 'flex',
    justifyContent: 'space-around',
    flexFlow: 'row wrap',
  },
  FlexLayoutRowColumnMobile: {
    flexDirection: 'row',
    [media.MOBILE_L]: {
      flexDirection: 'column',
    },
  },
  benefitDetails: {
    base: {
      display: 'flex',
      flexFlow: 'row',
      [media.TABLET]: {
        flexFlow: 'row wrap',
      },
    },
    btnContainerLeft: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 1 40%',
      margin: 10,
      minWidth: 200,
      [media.TABLET]: {
        flex: '0 1 100%',
      },
    },
    btnLeft: {
      display: 'flex',
      height: 70,
      marginBottom: 15,
    },
    labelStyle: {
      fontWeight: '600',
      fontSize: 22,
      textTransform: 'none',
    },
    textRight: {
      display: 'flex',
      flexDirection: 'column',
      flex: '0 1 60%',
      backgroundColor: '#f5f7f7',
      fontWeight: '400',
      padding: 10,
      margin: 10,
      [media.TABLET]: {
        flex: '0 1 100%',
      },
    },
  },
  textFieldUnderline: {
    color: colors.primary.magenta,
  },
  alerts: {
    red: {
      backgroundColor: '#f2dede',
      color: colors.pageText.primary,
    },
    yellow: {
      backgroundColor: '#fcf8e3',
      color: colors.pageText.primary,
    },
    green: {
      backgroundColor: '#dff0d8',
      color: colors.pageText.primary,
    },
  },
};
